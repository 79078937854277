import React from 'react'
import GooglePlay from '../components/GooglePlay'
import AppleStore from '../components/AppleStore'
import Layout from "../components/layout"

const DownloadPage = () => (
    <Layout>
        <div className="pa0 ma0 frendli-blue-bkg">
            <div className="frendli-oh-oh-bkg-img pa0 ma0 w-100 center">
                <div className="w-80 mt0 pt0 tc center mw7">
                    <h1 className="shawdow-white w-90 f3-l f4-m f5 tracked lh-title ma0 pt4 tl">
                        Frendli has launched!
                </h1>
                    <p className="shawdow-white f4-l f5 w-60-l w-50-m w-90 tracked lh-title tl">
                        Join now to start connecting with new friends in your area. We’ve launched experiences in San Diego, and will be launching other cities soon. Join now and let us know where to head to next.</p>
                </div>
            </div>
            <section className="w-100 signup-section frendli-blue-bkg tracked mt0 pt0 pb5 tc center ">
                <div className="w-80 mt0 pt0 tc center mw7">
                    <div className="w-100 tc center mt4 mt1-ns lh-copy">
                        <a href="/download/" className="b f4 black pl1 pr1 pl5-l pr4-l fw4 shawdow-white">
                            Sign Up For Frendli Today!
                        </a>
                        <div className="flex justify-center w-100-ns w-90 center">
                            <div className="center dn db-ns w-25 pa2" />
                                <GooglePlay />
                                <AppleStore />
                            <div className="center dn db-ns w-25 pa2" />
                        </div>
                    </div>

                </div>
            </section>
        </div>
    </Layout>
)
export default DownloadPage
